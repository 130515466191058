<template>
    <footer class="insert_bottom mo dp_inlineblock box_contents">
        <ul class="insert_bottom_spaceinfo pc">
            <li class="align_center">
                <div v-if="isshowbottom" class="align_center cursor">
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('show-curationinfo')"
                    >
                        <path
                            d="M18.636 16.828L13.686 21.778L12.272 20.364L18.636 14L25 20.364L23.586 21.778L18.636 16.828Z"
                            fill="white"
                        />
                    </svg>
                    <div class="space_curation_bottom">
                        <div
                            class="none"
                            v-for="(item, index) in sliceList"
                            :key="`bottom${index}`"
                            :style="`z-index:${curationlist.length - index}`"
                        >
                            <label v-if="item.cssclass === 'space_curation_info_box material'" :class="item.cssclass">
                                {{ item.url }}
                            </label>
                            <figure v-else :class="item.cssclass">
                                <img class="curation_info_box_img" :src="item.url" />
                            </figure>
                        </div>
                        <div v-if="curationlist.length > 9" class="more_spaceinfo_wrap">
                            <div class="more_spaceinfo"></div>
                            <div class="more_spaceinfo"></div>
                            <div class="more_spaceinfo"></div>
                        </div>
                    </div>
                </div>
                <div v-else class="align_center cursor">
                    <button :disabled="autorecommbtndisabled" class="auto_recommbtn" @click="$emit('auto-recommend')">
                        <img class="auto_recommbtn_img" src="/media/img/space/space_light.png" alt="자동추천" />
                        자동 추천 받을래요.
                    </button>
                </div>
            </li>
            <li>
                <ul class="insert_bottom_spaceinfo">
                    <button v-if="isshowprebtn" class="insert_page_btn prev" @click="$emit('click-pre')">이전</button>
                    <button class="insert_page_btn next" :disabled="btndisabled" @click="$emit('update-curation')">
                        {{ cofirmbtntext }}
                    </button>
                </ul>
            </li>
        </ul>
        <ul class="insert_bottom_spaceinfo mo flex_column">
            <li class="dp_flex insert_bottom_curation_info" v-if="isshowbottom">
                <img
                    class="curation_modal_img"
                    @click="$emit('show-curationinfo')"
                    src="/media/img/space/arrow_up.svg"
                    alt="큐레이션팝업"
                />
                <div class="mo_space_bottom_list dp_flex">
                    <div
                        class="none"
                        v-for="(item, index) in moSliceList"
                        :key="`bottom${index}`"
                        :style="`z-index:${curationlist.length - index}`"
                    >
                        <label v-if="item.cssclass === 'space_curation_info_box material'" :class="item.cssclass">
                            {{ item.url }}
                        </label>
                        <figure v-else :class="item.cssclass">
                            <img class="curation_info_box_img" :src="item.url" />
                        </figure>
                    </div>

                    <ul v-if="curationlist.length > 6" class="more_spaceinfo_wrap">
                        <li class="more_spaceinfo"></li>
                        <li class="more_spaceinfo"></li>
                        <li class="more_spaceinfo"></li>
                    </ul>
                </div>
            </li>
            <li class="auto_recommbtn_wrap" v-else>
                <button :disabled="autorecommbtndisabled" class="auto_recommbtn" @click="$emit('auto-recommend')">
                    <img class="auto_recommbtn_img" src="/media/img/space/space_light.png" alt="자동추천" />
                    자동 추천 받을래요.
                </button>
            </li>
            <li class="insert_bottom_btn_form">
                <button class="space_next_btn" :disabled="btndisabled" @click="$emit('update-curation')">
                    {{ cofirmbtntext }}
                </button>
            </li>
        </ul>
    </footer>
</template>
<script>
import { cloneDeep } from 'lodash-es';

export default {
  name: 'SpaceBottom',
  props: {
    btndisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    curationlist: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    },
    isshowbottom: {
      type: Boolean,
      default: true,
      required: false
    },
    isshowprebtn: {
      type: Boolean,
      default: true,
      required: false
    },
    autorecommbtndisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    cofirmbtntext: {
      type: String,
      default: '다음',
      required: false
    }
  },
  emits: ['click-pre', 'update-curation', 'show-curationinfo', 'auto-recommend'],
  data () {
    return {};
  },

  mounted () {},

  methods: {},
  computed: {
    sliceList () {
      const clone = cloneDeep(this.curationlist);
      if (clone.length > 10) {
        return clone.slice(0, 11);
      }
      return clone;
    },
    moSliceList () {
      const clone = cloneDeep(this.curationlist);
      if (clone.length > 6) {
        return clone.slice(0, 7);
      }
      return clone;
    }
  }
};
</script>
<style scoped src="@/assets/css/insertspace.css"></style>
<style scoped src="@/assets/css/spacemanage/spaceinsert.css"></style>
<style scoped src="@/assets/css/space/spacebottom.css"></style>
